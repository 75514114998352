import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

//Components
const ClientSideOnlyLazy = React.lazy(() =>
  import("../../components/Emprendimiento/main")
)
import About from '../../components/Emprendimientos/about-developments'
import UtilDevWrapper from "../../components/Emprendimientos/UtilDevWrapper";

// Conditional styles
import "../../styles/development/_dev-about-conditional.scss";

const IndexPage = (props) => {
  const {tag} = props;
  const isSSR = typeof window === "undefined";

  return(
    <Layout>
      <Seo title="Emprendimientos" />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideOnlyLazy tag={tag} hideCover={true}/>
        </React.Suspense> )}
      <UtilDevWrapper tag={tag} showMedia={true}/>
      <UtilDevWrapper tag={tag} showDownload={true}/>
      <About tag={tag} />
      <UtilDevWrapper tag={tag} showMap={true}/>
    </Layout>
  )
}

export default IndexPage
