import React, {useState, useEffect}  from 'react';
import { connect } from 'react-redux';

//Helpers
import { getLocation, getFakeAddress } from '../../helpers/helper.developments';
import { getMedia } from '../../helpers/helper.rendering';

// Components
import Map from '../map';
import Loading from '../Loading';
import FilesPro from '../Emprendimiento/files-pro';

const UtilDevWrapper = ({tag, showMedia = false, showDownload = false, showMap = false, developments, loading, api_key, dispatch}) => {
    
    // State to get all devs - loaded from MenuNav
    const [development, setDevelopment] = useState();
    // Search on cached devs and set state
    useEffect(() => {
        if(developments){
            setDevelopment(getDevelopmentByTag())
        }
    },[developments, tag])
    // Helper to filter devs by tag
    const getDevelopmentByTag = () => {
        const developmentsSearch = []
        for (const dev of developments) {
            if(dev.custom_tags.find(element => element.name.toLowerCase().replaceAll(" ","-") === tag?.toLowerCase().replaceAll(" ","-"))){
                developmentsSearch.push(dev)
            }
        }
        return developmentsSearch.find(element => element.custom_tags.find(atag => atag.name.toLowerCase() === 'portada'))
    }
  
    return !loading && development ? (
        <div className={`util-dev-wrapper ${showMedia ? 'media-content' : (showDownload ? 'download-content' : (showMap ? 'map-content' : 'default-content'))}`}>
            { showMedia ? <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-lg-3 d-flex justify-content-center align-items-center">
                        { (getMedia(development, "logo")?.length > 0) 
                            ? <img src={getMedia(development, "logo")[0]?.image} className="logo"
                                alt={getMedia(development, "logo")[0]?.description} 
                                    /> : null }
                    </div>
                    <div className="col-12 col-lg-9 d-flex justify-content-center align-items-center">
                        { (getMedia(development, "masterplan")?.length > 0)
                                ? <img src={getMedia(development, "masterplan")[0]?.image} className="masterplan"
                                    alt={getMedia(development, "masterplan")[0]?.description} /> : null }
                    </div>
                </div>
            </div> : null }
            { showDownload ? <FilesPro files={development.files} /> : null }
            { showMap ? <div className="ubicacion-main pt-2">
                <div class="container-fluid mb-5">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="mb-lg-2 text-uppercase location-title">Ubicación</h4>
                            <p class="pb-lg-0 pb-1">
                            { getFakeAddress(development) }
                            </p>
                        </div>
                    </div>
                </div>
                <Map coordenadas={{ geo_lat: development.geo_lat, geo_long: development.geo_long }} />
            </div> : null }
        </div>
    ) : <Loading absolute={true}/>;
}

export default connect(state => ({
    developments: state.developments.developments?.data?.objects,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko
}),null)(UtilDevWrapper);